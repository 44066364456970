import { createStyles, makeStyles } from '#/utils/theme';

import { THEME } from '../../../../constants/theme/constants';

export const useUserDataStyles = makeStyles(() =>
  createStyles({
    viewGrid: {
      columnGap: THEME.REM_SPACES[3.5],
      rowGap: THEME.REM_SPACES[0.5],
    },
    customHrefColor: {
      '&& > a': {
        color: THEME.PALETTES.TEXT.Action_Secondary,
        textDecoration: 'none',
        fontWeight: THEME.FONT_WEIGHTS.BOLD,
      },
    },
    wrap: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: THEME.REM_SPACES['1'],
      justifyContent: 'space-between',
    },
    editContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      justifyContent: 'space-between',
    },
    compactWrap: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: THEME.REM_SPACES['0'],
      justifyContent: 'space-between',
    },
  }),
);

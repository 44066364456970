import { CompanyType } from '#/constants/companyType/CompanyType';

import { IProductMix } from '#/interfaces/forms/campaignInvitation';
import { ICreateUserBrand } from '#/interfaces/forms/users';

import { SearchFilters } from '#/store/api/campaigns/campaignsInterfaces';
import {
  Companies,
  CompanyAccount,
  CompanyAccountAll,
  CompanyAccountAssociatedBrands,
  CompanyAccountAssociatedCompany,
  CompanyAccountBillingInfo,
  CompanyAccountById,
  CompanyAccountUser,
  CompanyAccountUserCompany,
  ICompanyHierarchy,
  PostCompanyAccount,
  PutCompanyAccountGeneralInfo,
  SelectedBrand,
} from '#/store/api/company/companyInterfaces';

import { baseApi } from '../../base';

const companyApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyAccountAssociatedBrands: builder.query<CompanyAccountAssociatedBrands[], { userId: string | undefined }>({
      query: ({ userId }) => `retailer/company-account/${userId}/associated-brands`,
    }),

    getCompanyAccountUserCompany: builder.query<
      CompanyAccountUserCompany,
      { userId: string | undefined; companyUserId: string | undefined }
    >({
      query: ({ userId, companyUserId }) => `/retailer/company-account/${userId}/${companyUserId}/user-company`,
      providesTags: ['USER_DATA'],
    }),

    putCompanyUserContactType: builder.mutation<
      void,
      {
        userId: string | undefined;
        userFormData: { keyContact: boolean; billingContact: boolean; defaultRole: string };
      }
    >({
      query: ({ userId, userFormData }) => ({
        url: `/retailer/company-user/${userId}/contact-type`,
        method: 'PUT',
        body: {
          keyContact: userFormData.keyContact,
          billingContact: userFormData.billingContact,
          defaultRole: userFormData.defaultRole,
        },
      }),
      invalidatesTags: (result, error) => (error ? [] : ['USER_DATA']),
    }),

    putEditCompany: builder.mutation<void, { data: PutCompanyAccountGeneralInfo; companyId: string | undefined }>({
      query: ({ data, companyId }) => ({
        url: `retailer/company-account/${companyId}/general-info`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['COMPANY_ACCOUNT', 'COMPANY_USERS', 'ASSOCIATED_BRANDS']),
    }),

    putEditBillingCompany: builder.mutation<void, { data: CompanyAccountBillingInfo; companyId: string | undefined }>({
      query: ({ data, companyId }) => ({
        url: `retailer/company-account/${companyId}/billing-info`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['COMPANY_ACCOUNT']),
    }),

    getCompanyAccount: builder.query<CompanyAccountById, { companyId: string | undefined }>({
      query: ({ companyId }) => `retailer/company-account/${companyId}`,
      providesTags: ['COMPANY_ACCOUNT'],
    }),

    getAllCompanies: builder.query<CompanyAccountAll[], { companyType: string }>({
      query: ({ companyType }) => `retailer/api/v1/company-account/all?companyType=${companyType}`,
    }),

    getCompaniesRetailerFilter: builder.query<{ filters: SearchFilters[] }, unknown>({
      query: () => `retailer/company-account/filters`,
    }),
    getCompaniesAssociateRetailerFilter: builder.query<{ filters: SearchFilters[] }, unknown>({
      query: () => `retailer/company-account/associate-filters`,
    }),
    getCompaniesAddUserRetailerFilter: builder.query<{ filters: SearchFilters[] }, unknown>({
      query: () => `retailer/company-account/add-user-filters`,
    }),

    postCompanyAccountSelectedBrands: builder.query<SelectedBrand[], { selectedBrandsIds: string[] }>({
      query: ({ selectedBrandsIds }) => {
        return {
          url: 'retailer/company-account/selected-brands',
          method: 'POST',
          body: { companyIds: selectedBrandsIds },
        };
      },
    }),

    postCompany: builder.mutation<void, { body: PostCompanyAccount }>({
      query: ({ body }) => ({
        url: 'retailer/company-account',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['USER_COMPANY']),
    }),

    getCompaniesRetailer: builder.query<
      Companies<CompanyAccount>,
      {
        pageNumber: number;
        numberOfRows: number;
        searchQuery: string;
      }
    >({
      query: ({ pageNumber, numberOfRows, searchQuery = '' }) => {
        const searchParams = new URLSearchParams();

        searchParams.set('pageNumber', pageNumber.toString());
        searchParams.set('numberOfRows', numberOfRows.toString());
        searchParams.set('searchQuery', searchQuery);

        return `retailer/company-account?${searchParams}`;
      },
      providesTags: ['USER_COMPANY'],
    }),
    getAssociatedCompanies: builder.query<CompanyAccountAssociatedCompany[], { companyId: string | undefined }>({
      query: ({ companyId }) => `retailer/company-account/${companyId}/associated-companies`,
      providesTags: ['ASSOCIATED_BRANDS'],
    }),
    putAssociatedCompanies: builder.mutation<void, { companyId: string | undefined; companyIds: string[] }>({
      query: ({ companyId, companyIds }) => ({
        url: `/retailer/company-account/${companyId}/associated-companies`,
        method: 'PUT',
        body: {
          companyIds,
        },
      }),
      invalidatesTags: ['ASSOCIATED_BRANDS'],
    }),
    getCompanyAccountProductMix: builder.query<IProductMix, { companyId: string }>({
      query: ({ companyId }) => `retailer/company-account/${companyId}/product-mix?companyType=BRAND`,
      providesTags: ['ASSOCIATED_BRANDS'],
    }),
    getCompanyUsers: builder.query<CompanyAccountUser[], { companyId: string | undefined; companyType: CompanyType }>({
      query: ({ companyId, companyType }) => `retailer/company-account/${companyId}/users?companyType=${companyType}`,
      providesTags: ['COMPANY_USERS'],
    }),
    getCompanyBrands: builder.query<{ companyId: string; companyName: string }[], void>({
      query: () => `retailer/company-account/brands`,
      providesTags: ['USER_COMPANY'],
      forceRefetch: () => true,
    }),
    getCompanyBrandsById: builder.query<
      { companyId: string; companyName: string }[],
      { companyId: string | undefined }
    >({
      query: ({ companyId }) => `retailer/company-account/brands/${companyId}`,
      providesTags: ['USER_COMPANY'],
      forceRefetch: () => true,
    }),
    getCompanyHierarchy: builder.query<ICompanyHierarchy, { companyId: string }>({
      query: ({ companyId }) => `retailer/company-account/hierarchy/${companyId}`,
      providesTags: ['USER_COMPANY', 'ASSOCIATED_BRANDS'],
    }),
    getHierarchyList: builder.query<CompanyAccountAssociatedCompany[], { companyId: string | undefined }>({
      query: ({ companyId }) => `retailer/company-account/hierarchy-list/${companyId}`,
      providesTags: ['ASSOCIATED_BRANDS'],
    }),
    putCompanyAccountProductMix: builder.mutation<void, { productId: string | undefined; data }>({
      query: ({ productId, data }) => ({
        url: `retailer/company-account/${productId}/product-mix`,
        method: 'PUT',
        body: data,
      }),
    }),
    postCompanyUserSignupForm: builder.mutation<void, { data: ICreateUserBrand }>({
      query: ({ data }) => {
        return {
          url: 'retailer/company-user/signup/form',
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetCompanyAccountUserCompanyQuery,
  usePutCompanyUserContactTypeMutation,
  useGetCompanyAccountAssociatedBrandsQuery,
  usePostCompanyAccountSelectedBrandsQuery,
  usePutEditCompanyMutation,
  useGetCompanyAccountQuery,
  usePutEditBillingCompanyMutation,
  useGetAllCompaniesQuery,
  useGetAssociatedCompaniesQuery,
  usePutAssociatedCompaniesMutation,
  useLazyGetCompanyAccountProductMixQuery,
  useGetCompaniesRetailerQuery,
  useGetCompaniesRetailerFilterQuery,
  usePostCompanyMutation,
  useLazyPostCompanyAccountSelectedBrandsQuery,
  useGetCompaniesAssociateRetailerFilterQuery,
  useGetCompaniesAddUserRetailerFilterQuery,
  useGetCompanyUsersQuery,
  useGetCompanyBrandsQuery,
  useGetCompanyHierarchyQuery,
  useGetHierarchyListQuery,
  useGetCompanyBrandsByIdQuery,
  usePutCompanyAccountProductMixMutation,
  usePostCompanyUserSignupFormMutation,
} = companyApi;

export { companyApi };
